import { createSelector } from 'reselect';
import { getFormSettings } from './formSettingsSelector';

export const getOrderForDietDetails = state =>
  state.orderFormDietDetailsReducer;

export const getCompanyDietDetails = state =>
  getOrderForDietDetails(state).companyDietDetails;

export const getCompanyDietCaloriesDetails = state =>
  getOrderForDietDetails(state).companyDietCaloriesDetails;

export const getCompanyDietCaloriesMenuConfiguration = createSelector(
  [getCompanyDietCaloriesDetails],
  calories => calories.filter(item => item.menuConfiguration)
);

export const isCateringHaveMealConfiguration = createSelector(
  [getCompanyDietDetails, getFormSettings],
  (diets, formSettings) => {
    const { orderSettings: { menuSelectionEnabled } = {} } = formSettings || {};

    return diets?.some(item => item?.menuConfiguration) && menuSelectionEnabled;
  }
);

export const getExchangeDiet = createSelector([getCompanyDietDetails], diets =>
  diets?.find(item => item?.menuConfiguration)
);
