import {
  userComApiAddTag,
  userComApiSendEvent,
  userComApiUpdateOrCreate,
} from '../api';
import { getTimeStamp } from '../utilis/getTimeStamp';

export const userComUpdateUserEvent = async (
  userCom,
  clientData,
  methodOfPurchase,
  code
) => {
  try {
    const {
      allowMessages,
      companyName,
      email,
      firstName,
      lastName,
      phoneNumber,
      taxId,
    } = clientData;

    const data = {
      agreement_data_processing: true,
      agreement_marketing: allowMessages,
      client_panel: !!methodOfPurchase ? methodOfPurchase !== 'guest' : true,
      company_name: companyName,
      email: email.toLowerCase(),
      first_name: firstName,
      last_name: lastName,
      NIP: taxId,
      phone_number: `${phoneNumber.includes('+') ? '' : '+'}${phoneNumber}`,
      user_id: email.toLowerCase(),
    };

    const res = await userComApiUpdateOrCreate(data, userCom);

    if (!!code) {
      const { code: codeName, discount, discountType } = code;
      const userPromoCode = {
        active_code: codeName || '',
        active_code_percentage: discountType === 'PERCENTAGE' ? discount : 0,
        active_code_quota: discountType === 'TOTAL' ? discount : 0,
      };
      await userComApiUpdateOrCreate(userPromoCode, userCom);
      await userComApiAddTag(userCom, { name: codeName });
    }

    if (
      (res?.status === 200 || res?.status === 201) &&
      methodOfPurchase === 'register'
    ) {
      const registerEventData = {
        client: userCom.userId,
        name: 'register',
        timestamp: getTimeStamp(),
      };
      userComApiSendEvent(registerEventData, userCom).catch(err =>
        console.log('register event', err)
      );
    }
  } catch (e) {
    console.log('update user', e);
  }
};
