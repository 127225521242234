import { getTimeStamp } from '../utilis/getTimeStamp';
import { userComApiSendProductEvent } from '../api';

export const userComRemoveProductEvent = (item, userCom) => {
  const {
    selectedDiet: { dietId, dietName },
  } = item;

  const data = {
    data: {
      product_id: dietId,
      product_name: dietName,
      URL: window.location.origin,
    },
    event_type: 'usuń',
    timestamp: getTimeStamp(),
    client: userCom.userId,
  };

  userComApiSendProductEvent(dietId, data, userCom).catch(e => console.log(e));
};
