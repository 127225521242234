import { getTimeStamp } from '../utilis/getTimeStamp';
import { userComApiSendProductEvent } from '../api';
import { BasketItemT } from 'typesRoot/basketItem.type';
import { UserComT } from '../utilis/userCom.type';

export const userComCheckoutProductEvent = (
  items: BasketItemT[],
  userCom: UserComT
) => {
  if (!items.length || !userCom) {
    return;
  }

  items.forEach((item: BasketItemT) => {
    const productId = item?.selectedDiet.dietId;
    const data = {
      data: {
        product_id: item?.selectedDiet.dietId,
        product_name: item?.selectedDiet.dietName,
        URL: window.location.origin,
      },
      event_type: 'checkout',
      timestamp: getTimeStamp(),
      client: userCom.userId,
    };
    userComApiSendProductEvent(productId, data, userCom).catch(e =>
      console.log(e)
    );
  });
};
