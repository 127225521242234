import React from 'react';
import { getOrderForm } from '@redux/selectors/orderFormSelector';
import {
  getBasketEdition,
  getFinalSumAfterDiscountAndDeliveryFee,
  getPromoCode,
} from '@redux/selectors/basketSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parsePrice } from 'utilsRoot/index';
import Button from '@components/common/Button';
import { Link } from 'react-router-dom';
import SmallReplaceIcon from '@assets/icons/smallReplaceIcon';
import CalendarSmallIllustration from '@assets/icons/calendarSmallIllustration';
import OrderSmallIllustration from '@assets/icons/orderSmallIllustration';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import ArrowRight1Icon from '@assets/icons/ArrowRight1Icon';
import {
  addOrderToBasket,
  updateItemInBasket,
} from '@components/pages/StepSelectDays/utils/selectDays.utils';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import { handleAddToCartEnhancedEcommerceData } from 'utilsRoot/enhanceEcommerce';
import {
  getPrice,
  getSelectedSideOrdersPrice,
} from 'utilsRoot/orderFormPrices';
import { getServerConfig } from '../../../general/generalSlice';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { addToOrRemoveFromCartGA4 } from '../../../GA4/events/ecommerceEvents';
import { AddToOrRemoveFromCartE } from '../../../GA4/types/GA4.type';
import RecalculatePriceLoader from '../../stepSummary/views/RecalculatePriceLoader';
import useCalculateItemPrice from '@services/hook/calculatePrices/useCalculateItemPrice.hook';
import useUserComAddEvent from '@userCom/hooks/useUserComAddEvent.hook';
import { useSalesManago } from '@salesManago/services/hooks/useSalesManago';
import useCalculateCartPrice from '@services/hook/calculatePrices/useCalculateCartPrice.hook';

const SummaryBar = () => {
  const dispatch = useAppDispatch();
  const sendDataToGTM = useGTMDispatch();

  const basketEdition = useAppSelector(getBasketEdition);
  const serverConfig = useAppSelector(getServerConfig);
  const orderForm = useAppSelector(getOrderForm);
  const selectedCity = useAppSelector(getSelectedCity);
  const promoCode = useAppSelector(getPromoCode);
  const finalAmountToPayAfterDiscountsAndDeliveryFee = useAppSelector(
    getFinalSumAfterDiscountAndDeliveryFee
  );

  const { isEdited } = basketEdition || null;
  const { companyId, enhancedEcommerce } = serverConfig || {};
  const {
    mealExchange: { days: customDays },
    selectedDays,
    sets,
  } = orderForm || {};

  const { isFetching, prices } = useCalculateItemPrice();
  const { prices: allPrices } = useCalculateCartPrice();

  const { trigger: triggerUserComAddEvent } = useUserComAddEvent(prices);
  const { triggerSalesManagoUpdateCart } = useSalesManago();

  const { totalDietWithDiscountsAndSideOrdersCost = 0 } = prices || {};

  const handleAnalytics = () => {
    triggerUserComAddEvent();
    triggerSalesManagoUpdateCart();
    if (!isEdited && enhancedEcommerce && companyId) {
      sendDataToGTM(
        handleAddToCartEnhancedEcommerceData(
          orderForm,
          selectedCity,
          promoCode?.code,
          getSelectedSideOrdersPrice(customDays, sets) +
            getPrice(orderForm, promoCode?.code, selectedCity?.sectorId),
          companyId
        )
      );
      addToOrRemoveFromCartGA4(
        orderForm,
        selectedCity,
        finalAmountToPayAfterDiscountsAndDeliveryFee,
        AddToOrRemoveFromCartE.Add
      );
    }
  };

  return (
    <div className="summary-bar">
      <RecalculatePriceLoader isFetching={isFetching} sizeSmall />

      <div className="container summary-bar__wrapper">
        <div className="summary-bar__container">
          <div className="summary-bar__content">
            <div className="summary-bar__info-container">
              <div>
                <SmallReplaceIcon
                  className="small-replace-icon"
                  height={48}
                  width={48}
                />
              </div>
              <p className="summary-bar__info-text">
                Możesz skonfigurować menu teraz lub też później w panelu klienta
              </p>
            </div>
            <div className="summary-bar__days-container">
              <CalendarSmallIllustration />
              <div className="summary-bar__days">
                <p className="label-l color-gray-500 spacer-bottom-4 white-space-nowrap">
                  Liczba dni:
                </p>
                <div className="summary-bar__days-count">
                  <p className="label-l color-gray-800">
                    {selectedDays?.length}
                  </p>
                  <Link to="/zamowienie">
                    <div className="display-flex align-items-center">
                      <div className="summary-bar__days-count-icon">
                        <FontAwesomeIcon
                          className="color-primary"
                          icon={faPen}
                        />
                      </div>
                      <p className="body-s color-gray-500">(zmień)</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="summary-bar__price-container">
              <div className="hidden-down-sm spacer-right-16">
                <OrderSmallIllustration />
              </div>
              <div>
                <p className="summary-bar__price-title">Suma zamówienia:</p>
                <p className="label-l color-gray-800">
                  {parsePrice(totalDietWithDiscountsAndSideOrdersCost)} zł
                </p>
              </div>
            </div>
          </div>
          <Link to="/koszyk">
            <Button
              icon={<ArrowRight1Icon color="white" />}
              iconRight
              label="Przejdź do koszyka"
              onClick={() => {
                isEdited
                  ? dispatch(updateItemInBasket())
                  : dispatch(addOrderToBasket(allPrices?.cart));
                handleAnalytics();
              }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SummaryBar;
