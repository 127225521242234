import {
  AddToOrRemoveFromCartE,
  GtagT,
  PurchaseGA4ArgsT,
} from '../types/GA4.type';
import { DietlyOpenCityT } from '@services/api/form/open/formOpenCities.api';
import { BasketItemT } from 'typesRoot/basketItem.type';
import { generateItem, generateItemsArray } from '../utils/GA4.util';
import { hashString } from 'utilsRoot/hashString.util';

export const viewItemGA4 = (
  basketItem: BasketItemT,
  city: DietlyOpenCityT,
  price: number
) => {
  window['gtag'] &&
    window['gtag']('event', 'view_item', {
      currency: 'PLN',
      value: price,
      items: [generateItem({ basketItem, city, price })],
    });
};

export const addToOrRemoveFromCartGA4 = (
  basketItem: BasketItemT,
  city: DietlyOpenCityT,
  totalPrice: number,
  eventType: AddToOrRemoveFromCartE
) => {
  window['gtag'] &&
    window['gtag']('event', eventType, {
      currency: 'PLN',
      value: totalPrice,
      items: [generateItem({ basketItem, city, price: totalPrice })],
    });
};

export const viewCartGA4 = (
  basket: Array<BasketItemT>,
  city: DietlyOpenCityT,
  totalPrice: number
) => {
  const items = generateItemsArray({ basket, city, price: totalPrice });

  window['gtag'] &&
    window['gtag']('event', 'view_cart', {
      currency: 'PLN',
      value: totalPrice,
      items,
    });
};

export const checkoutGA4 = (
  basket: Array<BasketItemT>,
  city: DietlyOpenCityT,
  totalPrice: number,
  coupon: string
) => {
  const items = generateItemsArray({ basket, city, price: totalPrice });

  window['gtag'] &&
    window['gtag']('event', 'begin_checkout', {
      currency: 'PLN',
      value: totalPrice,
      items,
      coupon,
    });
};

export const purchaseGA4 = async ({
  basket,
  city,
  clientId,
  clientLifecycleState,
  coupon,
  email,
  first_name,
  last_name,
  loggedIn,
  postal_code,
  previousOrdersNumber,
  shoppingCartId,
  street,
  totalPrice,
  typeOfPayment,
}: PurchaseGA4ArgsT) => {
  const items = generateItemsArray({
    basket,
    city,
    clientLifecycleState,
    price: totalPrice,
  });

  const toSendData: GtagT = {
    currency: 'PLN',
    value: totalPrice,
    items,
    coupon,
    shipping: 0,
    tax: 0,
    transaction_id: shoppingCartId,
    user_email: await hashString(email),
    user_id: clientId,
    user_logged_in: loggedIn ? 1 : 0,
    new_client: previousOrdersNumber ? 0 : 1,
    orders_cnt: previousOrdersNumber,
    affiliation: 'Online store',
    payment_method: typeOfPayment,
  };

  if (
    first_name &&
    last_name &&
    street &&
    city.name &&
    city.province &&
    postal_code
  ) {
    toSendData.address = {
      first_name: first_name && (await hashString(first_name)),
      last_name: last_name && (await hashString(last_name)),
      street: street && (await hashString(street)),
      city: city.name,
      region: city.province,
      postal_code,
    };
  }

  window['gtag'] && window['gtag']('event', 'purchase', toSendData);
};
