import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import styles from './collapsible.module.scss';

type Props = {
  bodyTextVisible: ReactNode | string;
  bodyTextHidable: string;
  headerText: string[];
  isOpenAtStart: boolean;
};

export const Collapsible = ({
  bodyTextHidable,
  bodyTextVisible,
  headerText,
  isOpenAtStart,
}: Props) => {
  const [isOpen, setIsOpen] = useState(isOpenAtStart);
  const [height, setHeight] = useState<number | undefined>(
    isOpenAtStart ? undefined : 0
  );
  const ref = useRef<HTMLDivElement>(null);

  const handleOpening = (e: MouseEvent) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    const resizeObserver = new ResizeObserver(el => {
      setHeight(el[0]?.contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <div>
      {bodyTextVisible}
      <div className={styles['content']} style={{ height }}>
        <div ref={ref}>{bodyTextHidable}</div>
      </div>
      <div className={styles['link']} onClick={handleOpening}>
        {isOpen ? headerText[1] : headerText[0]}
      </div>
    </div>
  );
};
