import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import {
  addUserComUserId,
  getServerConfig,
} from '@features/general/generalSlice';
import { checkIfUserComIsEnabled } from '../utilis/checkIfUserComIsEnabled';
import Cookies from 'universal-cookie';
import { userComApiGetUserKey } from '../api';
import { useAppSelector } from '@config/hooks';

const UserComScripts = () => {
  const dispatch = useDispatch();
  const { userCom } = useAppSelector(getServerConfig) || {};
  const cookies = new Cookies();
  const [searchCookie, setSearchCookie] = useState(true);
  const [cookie, setCookie] = useState('');

  useEffect(() => {
    if (checkIfUserComIsEnabled(userCom) && searchCookie) {
      const userComCookie = cookies.get('__ca__chat');
      setSearchCookie(false);
      if (!!userComCookie) {
        setCookie(userComCookie);
      } else {
        setTimeout(() => setSearchCookie(true), 2000);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCookie, userCom]);

  useEffect(() => {
    const addUserIdToUserCom = async () => {
      try {
        const userData = await userComApiGetUserKey(userCom, cookie);
        if (userData?.id) {
          dispatch(addUserComUserId(userData?.id));
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (checkIfUserComIsEnabled(userCom) && !userCom?.userId && !!cookie) {
      addUserIdToUserCom();
    }
  }, [cookie, dispatch, userCom]);

  return (
    <>
      {checkIfUserComIsEnabled(userCom) && (
        // @ts-ignore
        <Helmet>
          <script data-cfasync="false">{`window.civchat = { apiKey: "${userCom?.apiKey}"}`}</script>
          <script
            data-cfasync="false"
            src={`https://${userCom?.appSubdomain}.user.com/widget.js`}
          />
        </Helmet>
      )}
    </>
  );
};

export default UserComScripts;
