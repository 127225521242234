import React, { useEffect } from 'react';
import BoxStep from '../../../../components/common/BoxStep';
import { LoyaltyPoints } from '@components/pages/basket/components/LoyaltyPoints';
import PdfComponent from '../../../../components/pages/basket/components/PdfComponent';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import { getIfBasketContainsTestOrder } from '@redux/selectors/basketSelector';
import { useGetApiParamsQuery } from '@services/api/form/open/open.api';
import NextStepButton from './NextStepButton';
import { storeLoyaltyPointsSum } from '@redux/actions/profileActions';
import { getServerConfig } from '../../../general/generalSlice';
import { getProfileDetails } from '@redux/selectors/profileSelector';
import { useGetApiLoyaltyPointsSumQuery } from '@services/api/form/profile/profile.api';
import useFetchProfile from '../../../../hooks/useFetchProfile';
import PromoCodes from '../../../../components/pages/basket/components/PromoCodes';
import SummaryPrices from '../../stepSummary/views/summaryPrices/SummaryPrices';
import useCalculateCartPrice from '@services/hook/calculatePrices/useCalculateCartPrice.hook';

type Props = {
  nextStepIcon: boolean;
  setNextStepIcon: (b: boolean) => void;
};

const ShoppingCartSummary = ({ nextStepIcon, setNextStepIcon }: Props) => {
  const dispatch = useAppDispatch();

  const doesBasketContainsTestOrder = useAppSelector(
    getIfBasketContainsTestOrder
  );

  const profile = useAppSelector(getProfileDetails);
  const { companyId, enhancedEcommerce, pdfUpload } =
    useAppSelector(getServerConfig);

  const { data: params } = useGetApiParamsQuery(companyId, {
    skip: !companyId,
  });

  useFetchProfile(companyId);

  const { data } = useGetApiLoyaltyPointsSumQuery(undefined, {
    skip: !companyId || !profile?.firstName,
  });

  const { prices } = useCalculateCartPrice();

  const { cart: { totalCostToPay = 0 } = {} } = prices || {};

  useEffect(() => {
    if (data && companyId) {
      dispatch(storeLoyaltyPointsSum(data, companyId));
    }
  }, [companyId, data, dispatch]);

  return (
    <aside className="steps-page-basket__aside">
      <BoxStep borderTitle smallPaddings title="Podsumowanie koszyka">
        <SummaryPrices />

        <NextStepButton
          companyId={companyId}
          enhancedEcommerce={enhancedEcommerce}
          nextStepIcon={nextStepIcon}
          setNextStepIcon={setNextStepIcon}
        />
      </BoxStep>

      {!doesBasketContainsTestOrder && (
        <BoxStep smallPaddings>
          <PromoCodes />
        </BoxStep>
      )}

      {params?.loyaltyProgramEnabled && (
        <BoxStep smallPaddings>
          <LoyaltyPoints finalSum={totalCostToPay} />
        </BoxStep>
      )}

      {pdfUpload && (
        <BoxStep smallPaddings>
          <PdfComponent />
        </BoxStep>
      )}
    </aside>
  );
};

export default ShoppingCartSummary;
