export const userComApiUpdateOrCreate = (data, userCom) => {
  const { appSubdomain, token } = userCom;

  return fetch(
    `https://${appSubdomain}.user.com/api/public/users/update_or_create/`,
    {
      body: JSON.stringify(data),
      headers: {
        'authorization': `Token ${token}`,
        'content-type': 'application/json',
      },
      method: 'POST',
    }
  );
};

export const userComApiSendEvent = (data, userCom) => {
  const { appSubdomain, token } = userCom;

  return fetch(`https://${appSubdomain}.user.com/api/public/events/`, {
    body: JSON.stringify(data),
    headers: {
      'authorization': `Token ${token}`,
      'content-type': 'application/json',
    },
    json: true,
    method: 'POST',
  });
};

export const userComApiSendProductEvent = (customId, data, userCom) => {
  const { appSubdomain, token } = userCom;

  return fetch(
    `https://${appSubdomain}.user.com/api/public/products-by-id/${customId}/product_event/`,
    {
      body: JSON.stringify(data),
      headers: {
        'authorization': `Token ${token}`,
        'content-type': 'application/json',
      },
      json: true,
      method: 'POST',
    }
  );
};

export const userComApiGetUserKey = (userCom, key) => {
  const { appSubdomain, token } = userCom;

  return fetch(
    `https://${appSubdomain}.user.com/api/public/users/search/?key=${key}`,
    {
      headers: {
        authorization: `Token ${token}`,
      },
      method: 'GET',
    }
  ).then(res => res.json());
};

export const userComApiAddTag = (userCom, data) => {
  const { appSubdomain, token, userId } = userCom;

  return fetch(
    `https://${appSubdomain}.user.com/api/public/users/${userId}/add_tag/`,
    {
      body: JSON.stringify(data),
      headers: {
        'authorization': `Token ${token}`,
        'content-type': 'application/json',
      },
      json: true,
      method: 'POST',
    }
  );
};
