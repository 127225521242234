import axios from 'axios';

export const getCompanyWidgetSettings = (serverUrl: string) =>
  axios(`${serverUrl}/api/form/open/company-widget-settings`, {
    method: 'GET',
    headers: {
      'api-key': '123',
    },
  });

export const getCompanyWidgetStyles = (serverUrl: string) =>
  axios(`${serverUrl}/api/form/open/company-widget-styles`, {
    method: 'GET',
    headers: {
      'api-key': '123',
    },
  });

export const getApiMenu = (
  companyName: string,
  query: string,
  serverUrl: string,
  apiKey: string
) =>
  axios(
    `${serverUrl}/api/form/open/company-details/${companyName}/menus?searchCriteria=${query}`,
    {
      method: 'GET',
      headers: {
        'api-key': apiKey,
        'company-id': companyName,
      },
    }
  );

export const getApiCompanyMenuMeals = (
  companyName: string,
  activeDay: string,
  mealsIdsWithDietOptionCalories: string,
  serverUrl: string,
  apiKey: string,
  baseDietOptionId: string
) =>
  axios(
    `${serverUrl}/api/form/open/company-details/${companyName}/menuMeals?date=${activeDay}`,
    {
      method: 'GET',
      headers: {
        'api-key': apiKey,
        'company-id': companyName,
      },
      params: {
        mealsIdsWithDietOptionCalories: mealsIdsWithDietOptionCalories,
        baseDietOptionId,
      },
    }
  );
