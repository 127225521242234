import { getDeliveryTime } from '@userCom/utilis/getDeliveryTime';
import { parseSideOrdersObjectToArray } from '@userCom/utilis/createSideOrdersArray';
import { getTimeStamp } from '@userCom/utilis/getTimeStamp';
import { checkIfUserComIsEnabled } from '@userCom/utilis/checkIfUserComIsEnabled';
import { userComApiSendEvent, userComApiSendProductEvent } from '@userCom/api';
import { CalculatePriceItemT } from 'typesRoot/calculatePrice.type';
import { SelectedSideOrdersT } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';

type Props = {
  orderForm: any;
  prices: CalculatePriceItemT | undefined;
  selectedCity: any;
  selectedSideOrders: SelectedSideOrdersT;
  userCom: any;
};

export const generateUserComAddEvent = ({
  orderForm,
  prices,
  selectedCity,
  selectedSideOrders,
  userCom,
}: Props) => {
  if (!prices) {
    return () => {};
  }
  const {
    totalDietWithDiscountsAndSideOrdersCost,
    totalDietWithSideOrdersCost,
  } = prices || {};
  const {
    days,
    selectedCalories: { calories },
    selectedDays,
    selectedDiet: { dietId, dietName },
    selectedMeals,
    selectedOption: { dietOptionName },
    sets,
    testOrder,
  } = orderForm;
  const { name } = selectedCity;

  const addData = {
    client: userCom.userId,
    data: {
      //   additional_information: orderNote - nie ma tego w formularzu
      address_city: name,
      //   address_street: street - nie ma dostępu w formularzu na tym etapie
      calories: calories,
      daily_meals: selectedMeals?.length || 0,
      delivery_time_beginning: `${getDeliveryTime(
        Math.min.apply(null, selectedDays)
      )} 03:00:00`,
      delivery_time_days: days,
      delivery_time_end: `${getDeliveryTime(
        Math.max.apply(null, selectedDays)
      )} 22:00:00`,
      diet_name: dietName,
      product_id: dietId,
      product_name: dietName,
      quantity: sets,
      side_orders: parseSideOrdersObjectToArray(selectedSideOrders) || [],
      trial: testOrder,
      URL: window.location.origin,
      value: totalDietWithSideOrdersCost,
      valueAfterDaysDiscount: totalDietWithDiscountsAndSideOrdersCost,
      variant: dietOptionName,
    },
    name: 'add',
    timestamp: getTimeStamp(),
  };

  const productData = {
    data: {
      address_city: name,
      // address_street: street - nie ma dostępu w formularzu na tym etapie
      calories: calories,
      diet_name: dietName,
      product_id: dietId,
      product_name: dietName,
      side_orders: parseSideOrdersObjectToArray(selectedSideOrders),
      URL: window.location.origin,
      value: totalDietWithSideOrdersCost,
      valueAfterDaysDiscount: totalDietWithDiscountsAndSideOrdersCost,
      variant: dietOptionName,
    },
    event_type: 'add to cart',
    timestamp: getTimeStamp(),
    client: userCom.userId,
  };

  return () => {
    if (checkIfUserComIsEnabled(userCom)) {
      userComApiSendEvent(addData, userCom).catch(e => console.log(e));
      userComApiSendProductEvent(dietId, productData, userCom).catch(e =>
        console.log(e)
      );
    }
  };
};
