import { faCheck, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import React, { forwardRef } from 'react';
import { useAppDispatch } from '@config/hooks';
import {
  chooseExistingAddress,
  selectCityOrZipcode,
  selectDeliveryHours,
} from '@redux/actions/summaryActions';
import Button from '../../../../common/Button';
import { Select } from '../../../../common/select';
import { parseAddress } from '../../utils/thirdStepHelpers.utils';
import { classNamesUtil } from 'utilsRoot/classNamesUtil.util';
import { ProfileAddressWithSupportedCityT } from 'typesRoot/city/city.type';

type Props = {
  additionalTexts: any;
  deliveryTimesOptions: any;
  errors: any;
  orderSettings: any;
  selectedProfileAddress: any;
  setIsSidebarOpen: any;
  setTimeOfDelivery: any;
  sortProfileAddresses: any;
  timeOfDelivery: any;
  touched: any;
  hoursRef: any;
};

const ExistingAddress = forwardRef(
  (
    {
      additionalTexts,
      deliveryTimesOptions,
      errors,
      orderSettings,
      selectedProfileAddress,
      setIsSidebarOpen,
      setTimeOfDelivery,
      sortProfileAddresses,
      timeOfDelivery,
      touched,
    }: Props,
    hoursRef: any
  ) => {
    const dispatch = useAppDispatch();

    const selectAddress = (address: any) => {
      if (
        address?.supportedCity?.sectorId &&
        address.profileAddressId !== selectedProfileAddress?.profileAddressId
      ) {
        dispatch(selectCityOrZipcode(address?.supportedCity, 'city'));
        dispatch(chooseExistingAddress(address));
      }
      return null;
    };

    return (
      <ul className="steps-page-third__list">
        {sortProfileAddresses()?.length > 0 ? (
          <>
            <div className="label-m color-gray-500">Wybierz adres</div>
            {sortProfileAddresses()?.map(
              (address: ProfileAddressWithSupportedCityT) => {
                return (
                  <li
                    className={classNamesUtil('steps-page-third__list-item', {
                      'steps-page-third__list-item--active':
                        address.profileAddressId ===
                        selectedProfileAddress?.profileAddressId,
                      'color-gray-400': !address?.supportedCity?.sectorId,
                    })}
                    key={address.profileAddressId}
                    onClick={() => selectAddress(address)}
                    onKeyDown={() => {
                      selectAddress(address);
                    }}
                  >
                    <div>
                      <p>{parseAddress(address)}</p>
                      {!address?.supportedCity?.sectorId && (
                        <p className="spacer-top-4 body-m color-gray-400">
                          (adres nieobsługiwany w tym cateringu)
                        </p>
                      )}
                    </div>
                    {address.profileAddressId ===
                      selectedProfileAddress?.profileAddressId && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                  </li>
                );
              }
            )}
            <div className="display-flex align-items-center label-m color-gray-500 spacer-top-24">
              lub{' '}
              <Button
                buttonLink
                containerClass="spacer-left-16"
                icon={<FontAwesomeIcon icon={faPlusCircle} />}
                label="Dodaj nowy adres"
                onClick={() => {
                  setIsSidebarOpen(true);
                }}
              />
            </div>
            <div ref={hoursRef}>
              {(() => {
                if (orderSettings?.showHourPreference) {
                  return deliveryTimesOptions?.length ? (
                    <Field name="deliveryHour">
                      {/*@ts-ignore*/}
                      {({ field, form }) => (
                        <>
                          <Select
                            containerClass={`steps-page-third__medium-input spacer-bottom-24 spacer-top-24 ${
                              touched['deliveryHour'] &&
                              errors['deliveryHour'] &&
                              'steps-page-third__medium-input--error'
                            }`}
                            id="deliveryHour"
                            items={deliveryTimesOptions}
                            // @ts-ignore
                            label={
                              <>
                                Godzina dostawy
                                {orderSettings?.obligatoryHourPreference && (
                                  <span className="color-error spacer-left-4 ">
                                    *
                                  </span>
                                )}
                              </>
                            }
                            placeholder="Wybierz godzinę"
                            selectedItem={timeOfDelivery}
                            selectedItemKey="name"
                            selectItem={e => {
                              form.setFieldValue(field.name, e.name);
                              if (e?.value === 'empty') {
                                dispatch(selectDeliveryHours(''));
                              } else {
                                dispatch(selectDeliveryHours(e.name));
                              }
                              setTimeOfDelivery(e);
                            }}
                          />
                          {touched['deliveryHour'] &&
                            errors['deliveryHour'] && (
                              <p className="color-error body-m spacer-top-4 spacer-bottom-24">
                                {/*@ts-ignore*/}
                                {errors['deliveryHour']}
                              </p>
                            )}
                        </>
                      )}
                    </Field>
                  ) : (
                    <div className="body-m spacer-top-24">
                      <div className="label-m spacer-bottom-8 color-gray-500">
                        Godzina dostawy
                      </div>
                      Wybór godziny dostawy w danym mieście jest niedostępny
                    </div>
                  );
                }
                return null;
              })()}
            </div>
            {orderSettings?.showHourPreference &&
              additionalTexts?.hourPreference && (
                <p className="body-m">{additionalTexts.hourPreference}</p>
              )}
          </>
        ) : (
          <div className="label-m">
            <p className="spacer-bottom-8">Brak Adresów profilowych</p>
            <Button
              buttonLink
              colorPrimary
              label="Dodaj adres"
              onClick={() => {
                setIsSidebarOpen(true);
              }}
            />
          </div>
        )}
      </ul>
    );
  }
);

export default ExistingAddress;
