import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  resetOrderForm,
  restoreInitialOrderForm,
} from '@redux/actions/orderFormActions';
import { deleteItemFromSessionStorage } from '../helpers/basketHelpers';
import { deleteItem } from '@redux/actions/basketActions';
import Modal from 'react-modal';
import Button from '../../../common/Button';
import { clearBasketLocalStorage } from 'utilsRoot/index';
import { handleRemoveFromCartEnhancedEcommerceData } from 'utilsRoot/enhanceEcommerce';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import {
  getFinalSumAfterDiscountAndDeliveryFee,
  getPromoCode,
} from '@redux/selectors/basketSelector';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import MealIcon from '../../../../assets/icons/mealIcon';
import { checkIsSalesManagoEnabled } from '@salesManago/helpers';
import { salesManagoRemoveCart } from '@salesManago/events/shopCart';
import { getServerConfig } from '@features/general/generalSlice';
import { checkIfUserComWithUserIdIsEnabled } from '@userCom/utilis/checkIfUserComIsEnabled';
import { userComRemoveProductEvent } from '@userCom/productEvents/remove';
import { useAppSelector } from '@config/hooks';
import { addToOrRemoveFromCartGA4 } from '@features/GA4/events/ecommerceEvents';
import { AddToOrRemoveFromCartE } from '@features/GA4/types/GA4.type';
import { resetSideOrderState } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { selectOneOffs } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.slice';

export const RemoveDietModal = ({
  isOpen,
  item,
  itemPrice,
  numberOfSets,
  orderId,
  setIsOpen,
}) => {
  const dispatch = useDispatch();

  const selectedCity = useAppSelector(getSelectedCity);
  const { code } = useAppSelector(getPromoCode);
  const sendDataToGTM = useGTMDispatch();
  const { companyId, enhancedEcommerce, salesManago, serverUrl, userCom } =
    useAppSelector(getServerConfig);
  const finalAmountToPayAfterDiscountsAndDeliveryFee = useAppSelector(
    getFinalSumAfterDiscountAndDeliveryFee
  );

  return (
    <Modal
      className="modal modal-remove-from-basket"
      isOpen={isOpen}
      overlayClassName="overlay-form-fixed"
    >
      <div className="display-flex flex-direction-column justify-content-space-between">
        <div className="text-align-center display-flex flex-direction-column align-items-center padding-all-24">
          <MealIcon className="meal-icon-color" />
          <p className="label-l font-size-20 spacer-bottom-8">Usuń dietę</p>
          <p className="body-l spacer-bottom-16">
            Czy na pewno chcesz usunąć dietę z koszyka?
          </p>
        </div>
        <div className="display-flex justify-content-space-between padding-all-16 border-top-gray-200">
          <Button
            colorClean
            containerClass="cursor-pointer"
            label="Anuluj"
            onClick={() => setIsOpen(false)}
          />
          <Button
            label="Usuń dietę"
            onClick={() => {
              if (numberOfSets === 1) {
                clearBasketLocalStorage();
                dispatch(resetOrderForm());
                dispatch(restoreInitialOrderForm());
                dispatch(selectOneOffs([]));
                dispatch(resetSideOrderState());
              }

              deleteItemFromSessionStorage(orderId);
              dispatch(deleteItem(orderId));

              if (checkIfUserComWithUserIdIsEnabled(userCom)) {
                userComRemoveProductEvent(item, userCom);
              }

              if (checkIsSalesManagoEnabled(salesManago) && item.eventId) {
                salesManagoRemoveCart(
                  item.eventId,
                  serverUrl,
                  salesManago
                ).catch(e => {
                  console.log(e, 'e');
                });
              }

              if (enhancedEcommerce && companyId) {
                sendDataToGTM(
                  handleRemoveFromCartEnhancedEcommerceData(
                    item,
                    selectedCity,
                    code,
                    itemPrice,
                    companyId
                  )
                );
                addToOrRemoveFromCartGA4(
                  item,
                  selectedCity,
                  finalAmountToPayAfterDiscountsAndDeliveryFee,
                  AddToOrRemoveFromCartE.Remove
                );
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

RemoveDietModal.propTypes = {
  isOpen: PropTypes.bool,
  numberOfSets: PropTypes.number,
  orderId: PropTypes.string,
  setIsOpen: PropTypes.func,
};
