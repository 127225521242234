import React from 'react';
import BoxStep from '../../../../components/common/BoxStep';
import { ShoppingCartItem } from './ShopingCartItem';
import { getPrice } from 'utilsRoot/orderFormPrices';
import { useAppSelector } from '@config/hooks';
import {
  getBasket,
  getFinalSumAfterDiscountAndDeliveryFee,
  getNumberOfItemsInTheBasket,
  getPromoCode,
} from '@redux/selectors/basketSelector';
import AddAnotherDiet from './AddAnotherDiet';
import LoyaltyPointsInfo from './LoyaltyPointsInfo';
import {
  getAddressDetails,
  getSelectedCity,
} from '@redux/selectors/summaryReducer.selectors';
import { useEffectOnce } from 'react-use';
import { viewCartGA4 } from '../../../GA4/events/ecommerceEvents';
import { getServerConfig } from '../../../general/generalSlice';
import RecalculatePriceLoader from '../../stepSummary/views/RecalculatePriceLoader';
import useCalculateCartPrice from '../../../../services/hook/calculatePrices/useCalculateCartPrice.hook';
import { BasketItemT } from 'typesRoot/basketItem.type';
import OneOffSideOrders from '@features/orderForm/stepShoppingCart/views/oneOffs/OneOffSideOrders';
import { checkIsDietWithMenuConfiguration } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';

const ShoppingCartContent = () => {
  const { isError, isFetching, prices } = useCalculateCartPrice();

  const basket = useAppSelector(getBasket);
  const numberOfSets = useAppSelector(getNumberOfItemsInTheBasket);
  const address = useAppSelector(getAddressDetails);
  const selectedCity = useAppSelector(getSelectedCity);
  const finalAmountToPayAfterDiscountsAndDeliveryFee = useAppSelector(
    getFinalSumAfterDiscountAndDeliveryFee
  );
  const { companyId, enhancedEcommerce } = useAppSelector(getServerConfig);
  const promoCode = useAppSelector(getPromoCode);
  const { code } = promoCode || {};
  const { city } = address || {};

  useEffectOnce(() => {
    if (enhancedEcommerce && companyId) {
      viewCartGA4(
        basket,
        selectedCity,
        finalAmountToPayAfterDiscountsAndDeliveryFee
      );
    }
  });

  return (
    <div className="steps-page-basket__container">
      <BoxStep title="Zamówienie">
        <div className="position-rel">
          <RecalculatePriceLoader isFetching={isFetching || isError} />

          {basket?.map((item: BasketItemT, index: number) => {
            const {
              orderId,
              stepMenuConfiguration: { customDeliveryMeals },
              testOrder,
            } = item || {};

            return (
              <div key={orderId}>
                <ShoppingCartItem
                  item={item}
                  itemPrice={getPrice(item, code, city?.sectorId)}
                  key={orderId}
                  lastItem={index === basket?.length - 1}
                  mealExchange={checkIsDietWithMenuConfiguration(
                    customDeliveryMeals
                  )}
                  numberOfSets={numberOfSets}
                  prices={prices}
                  testOrder={testOrder}
                />
              </div>
            );
          })}

          <AddAnotherDiet />
        </div>

        <div className="steps-page__divide spacer-bottom-40 hidden-down-lg" />
        <OneOffSideOrders />
        <LoyaltyPointsInfo />
      </BoxStep>
    </div>
  );
};

export default ShoppingCartContent;
