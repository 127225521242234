import styles from '../../styles/SummaryPrices.module.scss';
import { useAppSelector } from '@config/hooks';
import {
  getLowestPriceAndPercent,
  getShouldShowOmnibusPrice,
} from '@redux/selectors/basketSelector';
import SummaryPricesBE from './SummaryPricesBE';
import { parsePrice } from 'utilsRoot/index';
import RecalculatePriceLoader from '../RecalculatePriceLoader';
import useCalculateCartPrice from '../../../../../services/hook/calculatePrices/useCalculateCartPrice.hook';

const SummaryPrices = () => {
  const { isError, isFetching, prices } = useCalculateCartPrice();

  const { lowestPercentage, lowestPrice } = useAppSelector(
    getLowestPriceAndPercent
  );
  const shouldShowOmnibusPrice = useAppSelector(getShouldShowOmnibusPrice);

  return (
    <div className={styles['container']}>
      <div className={styles['prices']}>
        <RecalculatePriceLoader isFetching={isFetching || isError} />

        {prices && <SummaryPricesBE prices={prices.cart} />}

        {shouldShowOmnibusPrice && (
          <div className="body-s text-align-right spacer-bottom-16">
            Najniższa oferowana cena w ciągu ostatnich 30 dni:{' '}
            {parsePrice(lowestPrice)} zł{' '}
            {Number(lowestPercentage) < 0 && (
              <span className="color-success">{lowestPercentage}%</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryPrices;
