import { getTimeStamp } from '../utilis/getTimeStamp';
import { userComApiSendEvent } from '../api';
import { checkIfOrderIsFirst } from '../utilis/checkIfOrderIsFirst';
import { getOrderTime } from '../utilis/getOrderTime';
import { getDayDiscount } from '../utilis/getDayDiscount';
import { createSideOrdersArray } from '../utilis/createSideOrdersArray';

const updateArray = (amount, array, data) => {
  for (let i = 0; i < amount; i++) {
    array.push(data);
  }
};

export const userComOrderEvent = (
  basket,
  promoCode,
  city,
  data,
  userCom,
  prices
) => {
  const { name } = city;
  const { migratedAddresses, orders, previousOrdersNumber } = data;
  const { discountPercentage, discountTotal } = orders[0];

  const ordersCost = [];

  basket.forEach(item => {
    const orderId = item?.orderId || '';
    const itemPrices = prices.items[orderId] || {};

    const {
      perDayDietCost = 0,
      totalDietWithDiscountsAndSideOrdersCost = 0,
      totalDietWithSideOrdersCost = 0,
    } = itemPrices;

    const {
      days,
      selectedCalories: { calories },
      selectedDiet: { dietId, dietImageUrl, dietName, discounts },
      selectedMeals,
      selectedOption: { dietOptionName },
      selectedSideOrders = {},
      sets,
    } = item;

    const dayDiscount = getDayDiscount(discounts, days);

    updateArray(sets, ordersCost, {
      //   additional_information: orderNote - nie ma tego w formularzu
      address_city: name,
      address_street: migratedAddresses[0]?.street,
      calories: calories,
      daily_cost: perDayDietCost,
      daily_meals: selectedMeals?.length || 0,
      discount_days: dayDiscount.discount,
      image_url: dietImageUrl,
      product_id: dietId,
      product_name: dietName,
      side_orders: createSideOrdersArray(selectedSideOrders) || [],
      value: totalDietWithSideOrdersCost,
      valueAfterDaysDiscount: totalDietWithDiscountsAndSideOrdersCost,
      variant: dietOptionName,
    });
  });

  // const ordersProductData = orders.map((order, index) => ({
  //   //   additional_information: ordersCost[index]?.additional_information, - nie ma tego w formularzu
  //   address_city: ordersCost[index]?.address_city,
  //   address_street: ordersCost[index]?.address_street,
  //   calories: ordersCost[index]?.calories,
  //   image_url: ordersCost[index]?.image_url,
  //   product_id: ordersCost[index]?.product_id,
  //   product_name: ordersCost[index]?.product_name,
  //   side_orders: ordersCost[index]?.side_orders,
  //   URL: window.location.origin,
  //   value: ordersCost[index]?.value,
  //   valueAfterDaysDiscount: ordersCost[index]?.valueAfterDaysDiscount,
  //   variant: ordersCost[index]?.variant,
  // }));

  const ordersData = orders.map((order, index) => {
    return {
      client: userCom.userId,
      data: {
        daily_cost: ordersCost[index]?.daily_cost,
        daily_meals: ordersCost[index]?.daily_meals,
        discount_days: ordersCost[index].discount_days,
        discount_days_used: !!ordersCost[index].discount_days,
        first: checkIfOrderIsFirst(previousOrdersNumber, index),
        order_delivery_time_beginning: `${order.dateFrom} 03:00:00`,
        order_delivery_time_days: order.deliveries.length,
        order_delivery_time_end: `${order.dateTo} 22:00:00`,
        order_time: getOrderTime(),
        purchase_id: order.orderId,
        side_orders: ordersCost[index]?.side_orders,
        trial: order.testOrder,
        value: ordersCost[index]?.value,
        valueAfterDaysDiscount: ordersCost[index]?.valueAfterDaysDiscount,
      },
      name: 'order',
      timestamp: getTimeStamp(),
    };
  });

  const orderDetails = {
    client: userCom.userId,
    data: {
      discount_code: promoCode.code,
      discount_amount: discountTotal,
      discount_percentage: discountPercentage,
      discount_used: !!(discountPercentage || discountTotal),
      first: ordersData.some(order => order.data.first),
      order_delivery_time_beginning: ordersData
        .map(order => order.data.order_delivery_time_beginning)
        .sort()[0],
      order_delivery_time_days_total: ordersData.reduce(
        (acc, order) => acc + order.data.order_delivery_time_days,
        0
      ),
      order_delivery_time_end: ordersData
        .map(order => order.data.order_delivery_time_end)
        .sort()[ordersData.length - 1],
      order_time: getOrderTime(),
      trial: ordersData.some(order => order.data.trial),
      value: ordersData.reduce((acc, order) => acc + order.data.value, 0),
      valueAfterDiscounts: ordersData.reduce(
        (acc, order) => acc + order.data.valueAfterDiscounts
      ),
    },
    name: 'order_details',
    timestamp: getTimeStamp(),
  };

  ordersData.forEach(eventData => userComApiSendEvent(eventData, userCom));

  userComApiSendEvent(orderDetails, userCom).catch(e => console.log(e));
};
