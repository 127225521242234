import { userComApiSendEvent } from '../api';
import { getTimeStamp } from '../utilis/getTimeStamp';

export const userComLoginEvent = userCom => {
  const data = {
    client: userCom.userId,
    name: 'login',
    timestamp: getTimeStamp(),
  };

  return userComApiSendEvent(data, userCom);
};
