import { roundToTwoDecimal } from './roundToTwoDecimal.util';

export const getSelectedDietCaloriesData = (
  selectedDietCaloriesIdPrices,
  selectedSectorId
) => {
  if (selectedDietCaloriesIdPrices?.length) {
    return (
      selectedDietCaloriesIdPrices?.find(sector => {
        return sector.sectorId === parseFloat(selectedSectorId);
      }) ?? {}
    );
  }
};

export const getPriceDeduction = (
  selectedMeals,
  selectedDiet,
  selectedSectorId
) => {
  if (!selectedDiet?.customDietOptions) {
    return 0;
  }
  return selectedMeals?.reduce((acc, curr) => {
    if (!curr.selected) {
      acc =
        acc +
        (curr.deductionPriceInSectors[selectedSectorId]
          ? curr.deductionPriceInSectors[selectedSectorId]
          : 0);
    }
    return acc;
  }, 0);
};

export const getCurrentDiscountDetails = (days, selectedDiet) => {
  const discounts = selectedDiet?.discounts;
  if (!discounts) {
    return null;
  }
  return (
    discounts &&
    discounts.reduce(
      (acc, curr) => {
        if (
          acc.minimumDays - (curr && curr.minimumDays) < 0 &&
          (curr && curr.minimumDays) <= days
        ) {
          return curr;
        }

        return acc;
      },
      { minimumDays: 0, discount: 0, discountType: 'PERCENTAGE' }
    )
  );
};

export const getPricePerDayBeforeDiscount = (orderForm, selectedSectorId) => {
  const { selectedDiet, selectedDietCaloriesIdPrices, selectedMeals } =
    orderForm;

  const selectedDietCaloriesData = getSelectedDietCaloriesData(
    selectedDietCaloriesIdPrices,
    selectedSectorId
  );

  const pricePerDay =
    selectedDietCaloriesData &&
    roundToTwoDecimal(selectedDietCaloriesData.price);

  const deductedPrice =
    pricePerDay -
    getPriceDeduction(selectedMeals, selectedDiet, selectedSectorId);

  const minimumPrice =
    selectedDietCaloriesData &&
    selectedDietCaloriesData.minimumPrice &&
    selectedDietCaloriesData.minimumPrice > 0
      ? selectedDietCaloriesData.minimumPrice
      : 0;
  return deductedPrice - minimumPrice <= 0 ? minimumPrice : deductedPrice;
};

export const getPricePerDay = (
  orderForm,
  code,
  selectedSectorId,
  isMenuConfigurationDiet
) => {
  const {
    days,
    mealExchange: {
      basicPricePerDay: { sideOrders },
      days: mealDays,
      totalPrice,
    },
    selectedDiet,
    selectedDietCaloriesIdPrices,
    testOrder,
  } = orderForm;

  const price = roundToTwoDecimal(
    getPricePerDayBeforeDiscount(orderForm, selectedSectorId)
  );
  const testOrderPrice = getTestOrderPrice(
    selectedDietCaloriesIdPrices,
    selectedSectorId
  );
  const discount = getCurrentDiscountDetails(days, selectedDiet)?.discount;
  const discountType = getCurrentDiscountDetails(
    days,
    selectedDiet
  )?.discountType;

  const separateCode = Object.keys(code).length > 0 && code.separate;

  const sumSideOrders = () => {
    const sideOrdersBasicPriceSum = sideOrders * days;
    const sideOrdersPriceAdjustment = Object.values(mealDays || {}).reduce(
      (acc, curr) => {
        acc = acc + curr.dayPriceAdjustment.sideOrders;
        return acc;
      },
      0
    );
    return sideOrdersBasicPriceSum + sideOrdersPriceAdjustment;
  };
  const totalPricePerDayMealExchange = (totalPrice - sumSideOrders()) / days;

  if (testOrder) {
    return testOrderPrice;
  }

  if (separateCode) {
    return isMenuConfigurationDiet ? totalPricePerDayMealExchange : price;
  }
  if (discount > 0 && discountType === 'PERCENTAGE') {
    return isMenuConfigurationDiet
      ? roundToTwoDecimal(
          (totalPricePerDayMealExchange * (100 - discount)) / 100
        )
      : roundToTwoDecimal((price * (100 - discount)) / 100);
  }
  if (discount > 0) {
    return isMenuConfigurationDiet
      ? totalPricePerDayMealExchange - discount
      : price - discount;
  }
  return isMenuConfigurationDiet ? totalPricePerDayMealExchange : price;
};

export const getDiscount = (
  pricePerDayBeforeDiscount,
  days,
  sets,
  selectedDiet,
  code
) => {
  const separateCode = Object.keys(code || {}).length > 0 && code.separate;
  const { discount, discountType } =
    getCurrentDiscountDetails(days, selectedDiet) || {};
  const pricePerDay = pricePerDayBeforeDiscount && pricePerDayBeforeDiscount;

  if (separateCode) {
    return 0;
  }
  if (discount > 0 && discountType === 'PERCENTAGE') {
    return (
      pricePerDay &&
      roundToTwoDecimal((pricePerDay * discount) / 100) * days * sets
    );
  }
  if (discount > 0) {
    return roundToTwoDecimal(discount * days * sets);
  }
  return 0;
};

export const getPrice = (orderForm, code, selectedSectorId) => {
  const { days, sets } = orderForm;
  const pricePerDay = roundToTwoDecimal(
    getPricePerDay(orderForm, code, selectedSectorId)
  );
  return pricePerDay && pricePerDay * days * sets;
};

export const getSelectedSideOrdersPrice = (customDays, sets) =>
  Object.values(customDays || {}).reduce((acc, curr) => {
    acc = curr.sideOrders.reduce((acc1, curr1) => {
      acc += curr1.defaultPrice * curr1.amount;
      return acc;
    }, 0);

    return acc;
  }, 0) * sets;

export const getTestOrderPrice = (prices, sectorId) => {
  if (prices && sectorId) {
    return prices?.find(price => price?.sectorId === sectorId)?.testPrice;
  }
};

export const calculateDiscountPerDay = (
  days,
  selectedDiet,
  mealExchangePrice,
  minimum,
  pricePerDay
) => {
  const currentDiscount = getCurrentDiscountDetails(days, selectedDiet);
  const calculateMinimumPrice =
    pricePerDay + mealExchangePrice < minimum
      ? minimum - pricePerDay
      : mealExchangePrice;

  if (currentDiscount?.discountType === 'TOTAL' && calculateMinimumPrice < 0) {
    return calculateMinimumPrice - currentDiscount.discount;
  }

  if (currentDiscount?.discountType === 'PERCENTAGE') {
    return (calculateMinimumPrice * (100 - currentDiscount.discount)) / 100;
  }

  return calculateMinimumPrice;
};

export const getDeductedPricePerDay = (
  orderForm,
  selectedSectorId,
  allMealsPrice
) => {
  const { days, selectedDiet, selectedDietCaloriesIdPrices, selectedMeals } =
    orderForm;

  const selectedDietCaloriesData = getSelectedDietCaloriesData(
    selectedDietCaloriesIdPrices,
    selectedSectorId
  );

  const pricePerDay =
    selectedDietCaloriesData && selectedDietCaloriesData.price;

  if (allMealsPrice) {
    const currentDiscount = getCurrentDiscountDetails(days, selectedDiet);

    if (currentDiscount?.discountType === 'TOTAL') {
      return pricePerDay - currentDiscount.discount;
    }

    if (currentDiscount?.discountType === 'PERCENTAGE') {
      return (pricePerDay * (100 - currentDiscount.discount)) / 100;
    }

    return pricePerDay;
  }

  return (
    pricePerDay -
    getPriceDeduction(selectedMeals, selectedDiet, selectedSectorId)
  );
};

export const getMinimumPricePerDay = (orderForm, selectedSectorId) => {
  const { selectedDietCaloriesIdPrices } = orderForm;

  const selectedDietCaloriesData = getSelectedDietCaloriesData(
    selectedDietCaloriesIdPrices,
    selectedSectorId
  );

  return selectedDietCaloriesData &&
    selectedDietCaloriesData.minimumPrice &&
    selectedDietCaloriesData.minimumPrice > 0
    ? selectedDietCaloriesData.minimumPrice
    : 0;
};

export const getDietExchangeSinglePrice = (
  price,
  currentDiscountDetails,
  code
) => {
  const discount = currentDiscountDetails?.discount;
  const discountType = currentDiscountDetails?.discountType;
  const separate = code.separate;

  if (discount > 0 && discountType === 'PERCENTAGE' && !separate) {
    return price && (price * (100 - discount)) / 100;
  }

  if (discount > 0 && discountType === 'TOTAL' && !separate) {
    return price && price - discount;
  }

  return price;
};

// Meals prices for days that were not changed
export const calculatedUnchangedDaysPriceSum = (
  meals,
  minimum,
  unchangedDaysCount,
  currentDiscountDetails,
  code
) => {
  const unchangedDayPrice = meals - minimum <= 0 ? minimum : meals;
  return (
    getDietExchangeSinglePrice(
      unchangedDayPrice,
      currentDiscountDetails,
      code
    ) * unchangedDaysCount
  );
};

// Meals prices for days that were changed
export const calculatedChangedDaysPriceSum = (
  mealExchangeDaysArray,
  meals,
  minimum,
  currentDiscountDetails,
  code
) => {
  return mealExchangeDaysArray.reduce((acc, curr) => {
    const adjustedPricePerDay = meals + curr.dayPriceAdjustment.meals;
    const pricePerDay =
      adjustedPricePerDay - minimum <= 0 ? minimum : adjustedPricePerDay;
    acc =
      acc +
      getDietExchangeSinglePrice(pricePerDay, currentDiscountDetails, code);
    return acc;
  }, 0);
};

export const currentDiscountDetails = (discounts, daysAmount) => {
  if (!discounts) return null;
  return (
    discounts &&
    discounts.reduce(
      (acc, curr) => {
        if (
          acc.minimumDays - (curr && curr.minimumDays) < 0 &&
          (curr && curr.minimumDays) <= daysAmount
        ) {
          return curr;
        }

        return acc;
      },
      { minimumDays: 0, discount: 0, discountType: 'PERCENTAGE' }
    )
  );
};
